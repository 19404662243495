<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal">
          <header class="modal-header">
            <slot name="header">
                Types de filtres            
            </slot>
            <button type="button" class="btn-close" @click="closeGenerateListeOffreModal">
              x
            </button>
          </header>
  
          <section class="modal-body">
            <ul>

                <li>
                    <input type="radio" value="prospection" v-model="tab[0]">
                    <span>Prospection</span><br>
                </li>

                <li>
                    <input type="radio" value="retune" v-model="tab[0]">
                    <span>Retune</span>
                </li>

                <li>
                  <span>La date de début</span><br>
                  <input type="date" v-model="tab[1]">
                </li>

                <li>
                  <span>La date de fin</span>
                    <input type="date"  v-model="tab[2]">
                </li>

            </ul>
          </section>
  
          <footer class="modal-footer">
            <button type="button" class="btn-green" @click="validerGenerateListeOffreModal">
              Oui
            </button>
            <button type="button" class="btn-red" @click="closeGenerateListeOffreModal">
              Non
            </button>
          </footer>
  
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'Modal',
    data() {
        return {
            tab : [null, null, null]
        }
    },

    methods: {

      closeGenerateListeOffreModal() {
        this.$emit('closeGenerateListeOffreModal');
      },

      validerGenerateListeOffreModal() {


        if(this.tab[0] == null || this.tab[1] == null || this.tab[2] == null) {
            alert("Veuilez vous saisir le type de filter");
        } else {
            this.$emit('validerGenerateListeOffreModal', this.tab);
        }


      }
  
  
    }
  };
  </script>
  
  <style scoped>
  
    .modal-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .modal {
      background: #FFFFFF;
      min-width: 500px;
      max-width: 600px;
      max-height: 400px;
      overflow: auto;
    }
  
    .modal-header,
    .modal-footer {
      padding: 15px;
      display: flex;
    }
  
    .modal-header {
      position: relative;
      border-bottom: 1px solid #eeeeee;
      color: #fd0d0d;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      justify-content: center;
    }
  

    /* Start Body */
      .modal-body {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0px 30px;
        font-size: 18px;
      }

      .modal-body ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .modal-body ul li{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
      }

      .modal-body ul li input, .modal-body ul li span {
       margin-left: 5px;
       margin-right: 5px;
      }

      .modal-body ul li input[type="date"] {
       height: 30px;
       width: 250px;
      }

    /* End Body */
  
  
    /* Start buttons */
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: #4AAE9B;
      background: transparent;
    }
  
    .modal-footer > .btn-green {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: green;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-right: 5px;
    }
  
    .modal-footer > .btn-red {
      border: 0px;
      font-size: large;
      width: 100px;
      background-color: red;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-left: 5px;
    }
    /* End Buttons */


    /* Start footer */
    .modal-footer {
      border-top: 1px solid #eeeeee;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    /* End Footer */
     
  </style>